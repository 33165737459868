"use client";

import React, { useEffect, useRef, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import Link from "next/link";
import { useSession } from "next-auth/react";
import SidebarLinkGroup from "./SidebarLinkGroup";
import Image from "next/image";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  handleClickWithNotification: (message: string) => void;
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  handleClickWithNotification,
}: SidebarProps) => {
  const sessionResult = useSession();
  const status = sessionResult?.status || "loading";
  const pathname = usePathname();
  const router = useRouter();

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);
  const [activeLink, setActiveLink] = useState(pathname);

  let storedSidebarExpanded = "true";

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
  );

  useEffect(() => {
    if (status === "unauthenticated") {
      // router.push("/auth/signin");
    }
  }, [status, router]);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (!sidebarOpen || key !== "Escape") return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  if (status === "loading") {
    return null;
  }

  if (status === "unauthenticated") {
    return null;
  }

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-60
      flex-col overflow-y-hidden bg-BgColor
      duration-300 ease-linear lg:static lg:translate-x-0 xl:w-65 2xl:w-70 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {/* <div className="flex items-center justify-center gap-2 px-6 py-5.5 lg:py-6.5">
        <Link href="/">
          <h2 className="text-2xl font-bold text-white">Company</h2>
        </Link>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        ></button>
      </div> */}
      <div
        style={{ position: "relative", width: "100%" }}
        className="items-center justify-center gap-2 px-6 py-5.5 lg:py-6.5"
      >
        <Image
          width={140}
          height={140}
          src="/images/logo/Apricot_logo.png"
          className="ml-5 justify-center text-center"
          alt="User"
          style={{ marginTop: "-13px" }}
        />
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#333333",
            position: "absolute",
            top: "calc(100% - 17px)",
            left: 0,
          }}
        ></div>
        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        ></button>
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="mt-5 px-4 py-4 lg:mt-9 lg:px-6">
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
              MENU
            </h3>

            <ul className="mb-6 flex flex-col gap-1.5">
              <li>
                <Link
                  href="/"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
                  ${
                    activeLink === "/"
                      ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
                      : "hover:bg-tabBackgroundColor"
                  }`}
                  onClick={() => setActiveLink("/")}
                >
                  Dashboard
                  {activeLink === "/" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>

              <li>
                <Link
                  href="https://app.mybrainlabs.ai/"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
                  ${
                    activeLink === "https://app.mybrainlabs.ai/"
                      ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
                      : "hover:bg-tabBackgroundColor"
                  }`}
                  onClick={() => setActiveLink("https://app.mybrainlabs.ai/")}
                >
                  CoPilot
                  {activeLink === "https://app.mybrainlabs.ai/" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>

              <li>
                <Link
                  href="/launch"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/launch"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={() => setActiveLink("/launch")}
                >
                  <span
                    className={
                      activeLink === "/launch" ? "text-orange-500" : ""
                    }
                  >
                    Launch
                  </span>
                  {activeLink === "/launch" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/clone"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/clone"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={() => setActiveLink("/clone")}
                >
                  <span
                    className={activeLink === "/clone" ? "text-orange-500" : ""}
                  >
                    AI Clone
                  </span>
                  {activeLink === "/clone" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/workforce"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1 ${
                    activeLink === "/workforce"
                      ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
                      : "hover:bg-tabBackgroundColor"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "Workforce tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/workforce" ? "text-orange-500" : ""
                    }
                  >
                    AI Workforce
                  </span>
                  {activeLink === "/workforce" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/marketplace"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/marketplace"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "Marketplace tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/marketplace" ? "text-orange-500" : ""
                    }
                  >
                    Marketplace
                  </span>
                  {activeLink === "/marketplace" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/leaderboards"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/leaderboards"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "Leaderboards tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/leaderboards" ? "text-orange-500" : ""
                    }
                  >
                    Leaderboards
                  </span>
                  {activeLink === "/leaderboards" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/engine"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/engine"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "News tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/engine" ? "text-orange-500" : ""
                    }
                  >
                    News
                  </span>
                  {activeLink === "/engine" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/training"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/training"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "Training tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/training" ? "text-orange-500" : ""
                    }
                  >
                    Training
                  </span>
                  {activeLink === "/training" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              {/* comment out support tab */}
              <li>
                <Link
                  href="/support"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/support"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickWithNotification(
                      "Support tab Data is coming soon...!",
                    );
                    setSidebarOpen(!sidebarOpen)
                  }}
                >
                  <span
                    className={
                      activeLink === "/support" ? "text-orange-500" : ""
                    }
                  >
                    Support
                  </span>
                  {activeLink === "/support" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
            </ul>
          </div>

          {/* <!-- More Group --> */}
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
              MORE
            </h3>

            <ul className="mb-6 flex flex-col gap-1.5">
              {status !== "authenticated" && (
                <SidebarLinkGroup
                  activeCondition={pathname ? pathname.includes("auth") : false}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          href="#"
                          className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
                        ${
                          pathname?.includes("auth")
                            ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
                            : "hover:bg-tabBackgroundColor"
                        }`}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          Authentication
                          <svg
                            className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                              open && "rotate-180"
                            }`}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                              fill=""
                            />
                          </svg>
                        </Link>
                        <div
                          className={`translate transform overflow-hidden ${
                            !open && "hidden"
                          }`}
                        >
                          <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                            <li>
                              <Link
                                href="/auth/signin"
                                className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium text-bodydark2 duration-300 ease-in-out
    ${
      activeLink === "/auth/signin"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                                onClick={() => setActiveLink("/auth/signin")}
                              >
                                <span
                                  className={
                                    activeLink === "/auth/signin"
                                      ? "text-orange-500"
                                      : ""
                                  }
                                >
                                  Sign In
                                </span>
                                {activeLink === "/auth/signin" && (
                                  <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2"></span>
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                href="/auth/signup"
                                className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium text-bodydark2 duration-300 ease-in-out
    ${
      activeLink === "/auth/signup"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                                onClick={() => setActiveLink("/auth/signup")}
                              >
                                <span
                                  className={
                                    activeLink === "/auth/signup"
                                      ? "text-orange-500"
                                      : ""
                                  }
                                >
                                  Sign Up
                                </span>
                                {activeLink === "/auth/signup" && (
                                  <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}
              <li>
                <Link
                  href="/profile"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/profile"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={() => setActiveLink("/profile")}
                >
                  <span
                    className={
                      activeLink === "/profile" ? "text-orange-500" : ""
                    }
                  >
                    Profile
                  </span>
                  {activeLink === "/profile" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  href="/settings"
                  className={`group relative flex items-center gap-2.5 rounded-lg px-4 py-2 font-medium duration-300 ease-in-out dark:text-bodydark1
    ${
      activeLink === "/settings"
        ? "border border-orange-500 bg-tabBackgroundColor text-orange-500"
        : "hover:bg-tabBackgroundColor"
    }`}
                  onClick={() => setActiveLink("/settings")}
                >
                  <span
                    className={
                      activeLink === "/settings" ? "text-orange-500" : ""
                    }
                  >
                    Settings
                  </span>
                  {activeLink === "/settings" && (
                    <span className="absolute right-4 top-1/2 h-2 w-2 -translate-y-1/2 text-orange-500"></span>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
