"use client";
import React, { useState, ReactNode, useEffect } from "react";
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
import { usePathname, useRouter } from "next/navigation";
import DarkModeSwitcher from "../Sidebar/DarkModeSwitcher";
import useColorMode from "@/hooks/useColorMode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const params = usePathname();
  const showHeader = params !== "/auth/signin";
  const [colorMode, setColorMode] = useColorMode();
  useEffect(() => {
    if (!showHeader) setColorMode;
  }, []);

  const notify = (message: any) => {
    toast.info(message, {
      position: "top-center",
      className: "text-center text-black",
    });
  };
  const handleClickWithNotification = (message: any) => {
      notify(message);
  };

  return (
    <>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="text-center text-black"
        />
        <Sidebar
          sidebarOpen={sidebarOpen}
          handleClickWithNotification={handleClickWithNotification}
          setSidebarOpen={setSidebarOpen}
        />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div
          className="bg-brelative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
          style={{ backgroundColor: "black" }}
        >
          {/* <!-- ===== Header Start ===== --> */}
          {showHeader && (
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          )}
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div
              className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10"
              style={{ backgroundColor: "black" }}
            >
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </>
  );
}
